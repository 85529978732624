<script>
import useVuelidate from '@vuelidate/core';
import {required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkReservationTooltip from '@components/general/AkReservationTooltip';
import PButton from 'primevue/button';

/* MIXINS */
import accommodationTypeConst from '@mixins/const/accommodationTypeConst';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import sidebarMixin from '@mixins/sidebarMixin';
import smilyStatusConst from '@mixins/const/smilyStatusConst';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import reservationDefautPaiementService from '@services/reservationDefautPaiementService';
import reservationSiteConst from '@mixins/const/reservationSiteConst';

export default {
  components: {
    AkReservationTooltip,
    PButton,
  },
  mixins: [
    accommodationTypeConst,
    loaderMixin,
    messageMixin,
    permissionsMixin,
    randomRef,
    reservationSiteConst,
    reservationTypesConst,
    sidebarMixin,
    smilyStatusConst,
    utilsMixin,
  ],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'accounting.defautpaiement',
    };
  },
  data() {
    return {
      loading: true,
      filter: {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
      list: [],
      items: 0,
      item: {},
      reservationTooltip: undefined,
    };
  },
  validations() {
    return {};
  },
  mounted() {
    let p1 = reservationDefautPaiementService.reservationsDefautPaiement(this.filter);
    p1.then((data) => {
      this.list = data.data;
      this.items = data.items;
    });

    Promise.all([p1]).then(() => {
      this.loading = false;
    });
  },
  methods: {
    clickSearch() {
      this.filter.page = 1;
      this.search();
    },
    search() {
      this.loading = true;
      reservationDefautPaiementService.reservationsDefautPaiement(this.filter).then((data) => {
        this.list = data.data;
        this.items = data.items;
        this.loading = false;
      });
    },
    displayPeriodForReservation(data) {
      return this.displayDates(data.dateStart, data.dateEnd);
    },
    displayTooltip(event, reservation) {
      this.$refs.tooltip.hide();
      let that = this;
      setTimeout(function () {
        that.reservationTooltip = reservation ? reservation : undefined;
        if (that.reservationTooltip) {
          let target = event.target;
          if (target !== undefined) that.$refs.tooltip.toggle(event, target);
        }
      }, 100);
    },
    rowClick(event) {
      let data = event.data;
      this.addReservation(data);
    },
    onPage(event) {
      this.filter.page = event.page + 1;
      this.filter.limit = event.rows;
      this.search();
    },
    onSort(event) {
      this.filter.sort = [];
      for (let sort of event.multiSortMeta) {
        this.filter.sort.push('' + sort.field + ':' + (sort.order === -1 ? 'DESC' : 'ASC'));
      }
      if (this.filter.sort.length === 0) {
        this.filter.sort.push('id:DESC');
      }
      this.search();
    },

    getLitigeStatusTooltip(data) {
      let status = '[' + this.displayDates(data.dateStartLitige, data.dateEndLitige) + ']';
      if (data.amountLitigeEstimated)
        status += ' ' + this.formatCurrencyEUR(this.formatAmount(data.amountLitigeEstimated));
      return status;
    },
    getReclamationStatusTooltip(data) {
      let status = '[' + this.displayDates(data.dateStartReclamation, data.dateEndReclamation) + ']';
      if (data.amountReclamationEstimated)
        status += ' ' + this.formatCurrencyEUR(this.formatAmount(data.amountReclamationEstimated));
      return status;
    },

    getLitigeDisplay(data) {
      return this.$t(data.dateStartLitige ? 'reservation.endLitige' : 'reservation.startLitige');
    },
    getReclamationDisplay(data) {
      return this.$t(data.dateStartReclamation ? 'reservation.endReclamation' : 'reservation.startReclamation');
    },

    openFilterPanel() {
      this.toggleReservationFilterList(this.filter, this.clickSearch, this.resetFilter);
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.toggleReservationFilterList(this.filter, this.search, this.resetFilter);
    },
  },
  computed: {},
};
</script>

<template>
  <GaView :ref="ref" :fullSearch="true" :title="$t('accounting.defautpaiement')">
    <template #search>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          class="btn btn-primary"
          @click="openFilterPanel()"
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          label="Filtrer" />
      </div>
    </template>

    <template #content>
      <DataTable
        ref="table"
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="50"
        :lazy="true"
        :totalRecords="this.items"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        sort-mode="multiple"
        class="table pointer"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        striped-rows
        @row-click="rowClick($event)"
        @page="onPage($event)"
        @sort="onSort($event)">
        <template #empty>
          {{ $t('reservation.empty') }}
        </template>

        <Column :header="$t('reservation.localizer')">
          <template #body="slotProps">
            <span class="pointer">
              <router-link
                :to="{
                  name: 'reservationPage',
                  params: {reservationId: slotProps.data.id},
                }">
                {{ slotProps.data.localizer }}
              </router-link>
            </span>
          </template>
        </Column>

        <Column :header="$t('reservation.accommodation')">
          <template #body="slotProps">
            <span class="pointer">
              <router-link :to="{name: 'accommodationPage', params: {accommodationId: slotProps.data.accommodationId}}">
                {{ slotProps.data.accommodationName }}
              </router-link>
            </span>
          </template>
        </Column>

        <Column :header="$t('reservation.guest')">
          <template #body="slotProps">
            <span class="pointer">
              {{ slotProps.data.guestDisplay }}
            </span>
          </template>
        </Column>

        <Column :header="$t('reservation.statusSmily')">
          <template #body="slotProps">
            <span class="pointer">
              {{ displayForSmilyStatusConst(slotProps.data.statusSmily) }}
            </span>
          </template>
        </Column>

        <Column :header="$t('reservation.period')">
          <template #body="slotProps">
            <span class="pointer">
              {{ displayPeriodForReservation(slotProps.data) }}
            </span>
          </template>
        </Column>

        <Column :header="$t('reservation.reservation_site')">
          <template #body="slotProps">
            <span class="pointer">
              {{ displayReservationSite(slotProps.data.reservationSite) }}
            </span>
          </template>
        </Column>

        <Column :header="$t('reservation.managementStatus')" v-if="!this.isOwner()">
          <template #body="slotProps">
            <div>
              <div v-if="slotProps.data.dateStartLitige" :title="getLitigeStatusTooltip(slotProps.data)">
                <span class="pointer">
                  {{ $t(slotProps.data.dateEndLitige ? 'reservation.litigeEnded' : 'reservation.litigeStarted') }}
                </span>
              </div>
              <div v-if="slotProps.data.dateStartReclamation" :title="getReclamationStatusTooltip(slotProps.data)">
                <span class="pointer">
                  {{
                    $t(
                      slotProps.data.dateEndReclamation
                        ? 'reservation.reclamationEnded'
                        : 'reservation.reclamationStarted',
                    )
                  }}
                </span>
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>

    <template #extra>
      <AkReservationTooltip ref="tooltip" v-model="reservationTooltip" />
    </template>
  </GaView>
</template>
