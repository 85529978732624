<script>
import useVuelidate from '@vuelidate/core';
import moment from 'moment/moment';
import {maxLength, required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkButtonValidation from '@components/v2/general/AkButtonValidation';
import AkCalendar from '@components/v2/input/AkCalendar';
import AkDropdown from '@components/v2/input/AkDropdown';
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkInputTextArea from '@components/v2/input/AkInputTextArea';
import AkSidebar from '@components/v2/general/AkSidebar';
import GaMessages from '@components/ga/layout/GaMessages';
import AkTime from '@components/v2/input/AkTime';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import paymentSourceConst from '@mixins/const/paymentSourceConst';

/* SERVICES */
import reservationPlanningService from '@services/reservationPlanningService';

export default {
  components: {
    AkButtonValidation,
    AkCalendar,
    AkDropdown,
    AkFormSubmitted,
    AkInputTextArea,
    AkSidebar,
    GaMessages,
    AkTime,
  },
  mixins: [messageMixin, utilsMixin, paymentSourceConst],
  setup() {
    return {v$: useVuelidate()};
  },
  emits: ['done'],
  data() {
    return {
      errors: [],
      submitted: false,
      visible: false,
      reservationHote: {},
      accommodations: [],
      disableResaHoteAccommodation: false,
    };
  },
  validations() {
    return {
      reservationHote: {
        accommodationId: {required},
        dateStart: {required},
        dateEnd: {required},
        hourCheckin: {required},
        hourCheckout: {required},
      },
    };
  },
  mounted() {},
  methods: {
    show(reservationHote, accommodations, disableResaHoteAccommodation) {
      this.reservationHote = reservationHote;
      this.accommodations = accommodations;
      this.disableResaHoteAccommodation = disableResaHoteAccommodation;

      this.errors = [];
      this.submitted = false;
      this.visible = true;
    },

    hide() {
      this.submitted = false;
      this.visible = false;
    },

    handle() {
      this.errors = [];
      this.submitted = true;
      this.v$.$touch();

      if (this.v$.$error) {
        this.$refs.submit.init();
        return;
      }
      reservationPlanningService
        .createReservationHote(this.reservationHote)
        .then((data) => {
          if (this.reservationHote.id) {
            this.success(this.$t('unavailability.edit_success'), true, true);
          } else {
            this.success(this.$t('unavailability.add_success'), true, true);
          }
          this.$emit('done');
          this.$nextTick(this.hide);
        })
        .catch((e) => {
          if (e.response) {
            let message = this.msgForErr(this.$t, e);
            this.$refs.sidebarMessages.error(message, true, true);
            this.$refs.submit.init();
          }
        });
    },
  },
  computed: {
    minDateEnd() {
      if (!this.reservationHote) return null;
      if (this.reservationHote.dateStart) {
        if (moment(this.reservationHote.dateStart).endOf('day').isBefore(moment().endOf('day'))) {
          return moment().toDate();
        }
        return moment(this.reservationHote.dateStart).add(1, 'd').toDate();
      }
      return moment().add(1, 'd').toDate();
    },
    hasManyAccommodations() {
      return this.accommodations.length > 1;
    },
    startDisabled() {
      if (moment(this.reservationHote.dateStart).isSameOrBefore(moment())) {
        return true;
      }
      return false;
    },
  },
  watch: {
    'reservationHote.dateStart'(newVal) {
      if (moment(this.reservationHote.dateStart).isAfter(moment(this.reservationHote.dateEnd))) {
        this.reservationHote.dateEnd = undefined;
      }
      if (this.reservationHote.hourCheckin) {
        let oldHour = moment(this.reservationHote.hourCheckin).hour();
        let oldMin = moment(this.reservationHote.hourCheckin).minute();
        this.reservationHote.hourCheckin = moment(this.reservationHote.dateStart).hour(oldHour).minute(oldMin).toDate();
      } else {
        this.reservationHote.hourCheckin = moment(this.reservationHote.dateStart).hour(16).minute(0).toDate();
      }
    },
    'reservationHote.dateEnd'(newVal) {
      if (this.reservationHote.hourCheckout) {
        let oldHour = moment(this.reservationHote.hourCheckout).hour();
        let oldMin = moment(this.reservationHote.hourCheckout).minute();
        this.reservationHote.hourCheckout = moment(this.reservationHote.dateEnd).hour(oldHour).minute(oldMin).toDate();
      } else {
        this.reservationHote.hourCheckout = moment(this.reservationHote.dateEnd).hour(10).minute(0).toDate();
      }
    },
  },
};
</script>

<template>
  <AkSidebar :baseZIndex="9998" v-model:visible="visible" block-scroll position="right" :dismissable="true">
    <template #container>
      <div class="mb-4">
        <GaMessages ref="sidebarMessages" />
      </div>

      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">
          {{ this.reservationHote.id ? $t('unavailability.edit') : $t('unavailability.add') }}
        </div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>

      <div class="p-sidebar-content">
        <perfect-scrollbar class="h-full flex flex-col gap-4">
          <AkFormSubmitted :submitted="submitted" class="flex flex-col gap-4" reference="form" @submit="handle()">
            <div class="flex flex-col gap-2">
              <AkDropdown
                v-if="this.hasManyAccommodations"
                v-model="reservationHote.accommodationId"
                :label="$t('accommodation.name')"
                :options="this.accommodations"
                :validator="v$.reservationHote.accommodationId"
                class-name="col-md-6"
                option-label="display"
                :disabled="this.disableResaHoteAccommodation"
                option-value="id" />
              <div class="flex gap-2">
                <AkCalendar
                  :disabled="startDisabled"
                  v-model="reservationHote.dateStart"
                  :label="this.$t('unavailability.dateStart')"
                  :validator="v$.reservationHote.dateStart"
                  :min-date="new Date()"
                  class-name="flex-1" />
                <AkCalendar
                  v-model="reservationHote.dateEnd"
                  :label="this.$t('unavailability.dateEnd')"
                  :min-date="minDateEnd"
                  :validator="v$.reservationHote.dateEnd"
                  class-name="flex-1" />
              </div>
              <div class="flex gap-2">
                <AkTime
                  :disabled="startDisabled"
                  v-model="reservationHote.hourCheckin"
                  :label="this.$t('unavailability.hourCheckin')"
                  :validator="v$.reservationHote.hourCheckin"
                  class-name="flex-1" />
                <AkTime
                  v-model="reservationHote.hourCheckout"
                  :label="this.$t('unavailability.hourCheckout')"
                  :validator="v$.reservationHote.hourCheckout"
                  class-name="flex-1" />
              </div>
              <AkInputTextArea
                v-model="reservationHote.comment"
                :label="this.$t('unavailability.comment')"
                class-name="" />
            </div>
            <div class="flex justify-end">
              <AkButtonValidation
                ref="submit"
                :label="this.reservationHote.id ? $t('update') : $t('add')"
                @action="handle" />
            </div>
          </AkFormSubmitted>
        </perfect-scrollbar>
      </div>
    </template>
  </AkSidebar>
</template>
